.metrics {
    background-color: transparent;

    .header {
        margin-top: 20px;
        color: #818181;
        font-size: 100%;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        font-weight: 500;
    }

    .value {
        font-size: 140%;
        font-weight: 500;
    }
}
