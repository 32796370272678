* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
        sans-serif;
    font-weight: 300;
    background-color: #eee;
}

html {
    background-color: #242729;
}
