.footer {
    background-color: #242729;
    color: #6a737c;
    margin-top: 20px;
    padding: 10px 0 50px 0;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-bottom: 10px;
            margin-right: 20px;
        }
    }

    .topLists {
        font-size: 120%;
    }

    .nav {
        margin-top: 50px;
        font-size: 120%;
        text-align: center;
    }

    .tip {
        margin-top: 20px;
        font-size: 120%;
        text-align: center;
    }

    .smiley {
        vertical-align: middle;
    }

    :global(.row) {
        background-color: #242729;
        margin: 8px 0;
    }

    :global(.btn) {
        font-size: 100%;
    }

    a:link,
    a:visited {
        color: #999;
        transition: color 0.2s;
    }

    a:active,
    a:focus,
    a:hover {
        color: #eee;
        text-decoration: none;
    }
}
