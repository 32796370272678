.header {
    font-size: 120%;

    .logo {
        &:focus,
        &:hover {
            cursor: pointer;
        }
    }

    .link {
        color: #777;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
        padding: 10px 15px;
        line-height: 20px;

        &:focus,
        &:hover {
            cursor: pointer;
            color: #333;
            background-color: transparent;
        }
    }

    .link,
    .link:link,
    .link:visited {
        transition: color 0.2s;
    }

    .link:global(.active) {
        background-color: rgba(39, 174, 95, 0.1);
        background-image: none;
    }

    .crypto {
        letter-spacing: 0.001em;
        font-style: italic;
        font-weight: 600;
        color: #242729;
        font-size: 160%;
        white-space: nowrap;
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.11);
    }

    .currency {
        letter-spacing: 0.001em;
        font-style: italic;
        color: #329239;
        font-size: 140%;
        white-space: nowrap;
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.11);
    }

    .underline {
        padding: 5px 0 3px 0;
        border-top: 2px solid #329239;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: 2px solid #329239;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.11);
    }
}
