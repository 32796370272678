.viewTopCoins {
    padding: 18px 0;
    background-color: #329239;
    color: #fff;
    border-radius: 0 0 4px 4px;
    transition: background-color 0.2s;
    font-size: 160%;
    text-align: center;
    font-weight: 600;

    &:hover {
        background-color: #469c4c;
        transition: background-color 0.2s;
    }

    &:active {
        background-color: #5aa760;
    }

    p {
        margin: 0;
    }
}
